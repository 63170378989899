
<template>
  <section class="dtc-main-section mt-2 mr-2">
    <LocalStuDataEdit @updateLocalStuData="getData"></LocalStuDataEdit>
    <ContractRepayRecord></ContractRepayRecord>
    <ProgressRecord></ProgressRecord>
    <FeeClaim></FeeClaim>
    <section class="search-block">
      <h5 class="big-title">報考資格審查</h5>
      <div class="dtc-search1">
        <b-input-group prepend="姓名">
          <b-input
            v-model.trim="searchName"
            placeholder="請輸入姓名"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input
            v-model.trim="searchId"
            placeholder="請輸入身分證字號"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="success" @click="getExcel" v-if="!loadingExcel">匯出清單</b-button>
        <b-button variant="success" disabled v-else><b-spinner small type="grow"></b-spinner>Loading...</b-button>
        <b-button variant="success"  @click="$refs.file.click()">上傳名單</b-button>
        <input  ref="file" type="file" accept=".pdf" @change="fileChange" hidden />
        <b-button variant="outline-primary" @click="downloadfile">上傳範例下載</b-button>
        <!--b-button variant="primary" @click="searchDb">進行查詢</b-button-->
        <!--b-button variant="danger" @click="clearSearch">清除條件</b-button-->
        <!--b-button variant="success" @click="getExcel" v-if="!loadingExcel">匯出清單</b-button-->
        <!--b-button variant="success" disabled v-else><b-spinner small type="grow"></b-spinner>Loading...</b-button-->
        <!--b-button variant="success"  @click="$refs.file.click()">上傳名單</b-button-->
        <!--input  ref="file" type="file" accept=".xlsx, .xls" @change="fileChange" hidden /-->
        <!--b-button variant="outline-primary" @click="downloadfile">上傳範例下載</b-button-->
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <!-- <pre>{{items[0]}}</pre> -->

    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color: #39312e"
      :style=" i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'"
    >

      <div :title="item.name">
        {{ item.name }}
      </div>
      <div :title="item.id">
        {{ item.id }}
      </div>
      <div :title="item.gender">
        {{ item.gender }}
      </div>
      <div :title="item.birthday">
        {{ new Date(item.birthday).toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}
      </div>
      <div :title="item.phoneNumber">
        {{ item.phoneNumber }}
      </div>
      <div :title="item.address">
        {{ item.address }}
      </div>
      <div :title="item.nationality">
        {{ item.nationality }}
      </div>
      <div :title="item.preferenceOrder">
        {{ item.preferenceOrder }}
      </div>
      <div class="UpPreview">
        <button :title="item.qualificationProof" class="uploadfile0" @click="openFileInput(item)">上傳</button>
        <input :data-index="i" type="file" accept=".pdf" @change="fileChangeQualification(item.id, items.indexOf(item))" hidden />
        <button @click="redirectToPreviewPage(item.id)">預覽</button>
      </div>

      <div class="UpPreview">
        <button :title="item.studyPlan" class="uploadfile" @click="openFileInputStudyPlan(item)">上傳</button>
        <input :data-index="i+1" type="file" accept=".pdf" @change="fileChangeStudyPlan(item.id, items.indexOf(item))" hidden />
        <button @click="redirectstudyPlan(item.id)">預覽</button>
      </div>

    </main>

    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import LocalStuDataEdit from "@/components/systemmanage/LocalStuDataEdit.vue";
import ContractRepayRecord from "@/components/systemmanage/ContractRepayRecord";
import ProgressRecord from "@/components/systemmanage/ProgressRecord";
import FeeClaim from "@/components/systemmanage/FeeClaim";

import {
  genderObject,
  countriesObject,
  localStatusObject,
  allServiceObject,
  domainObject,
} from "@/constant.js";

const headers = [
  "姓名",
  "身分證字號",
  "性別",
  "出生年月日",
  "通訊電話",
  "通訊地址",
  "籍別",
  "志願排序",
  "資格證明",
  "學習計畫書",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });
// schoolObject.unshift({ value: null, text: "全部" });
// majorObject.unshift({ value: null, text: "全部" });
// countryObject.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      filename: "",
      fakeData: [],
      countryObject: [],
      genderObject,
      schoolObject: [],
      majorObject: [],
      countriesObject,
      localStatusObject,
      allServiceObject,
      hometownObject: [],
      serviceOption: [],
      searchName: "",
      searchId: "",
      searchGender: null,
      searchBirthday: {
        year: "",
        month: "",
        day: "",
      },
      searchSchool: null,
      searchEntryYear: null,
      searchEndYear: null,
      searchMajor: null,
      searchCategory: null,
      searchHometown: null,
      searchStatus: null,
      searchServiceNow: null,

      loadingExcel: false,
      domainObject,

      allHealthOption: [],
    };
  },
  components: {
    LocalStuDataEdit,
    ContractRepayRecord,
    ProgressRecord,
    FeeClaim,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
    isHealthCenterCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const roleHealthBureauArr = JSON.parse(
        sessionStorage.getItem("roleHealthBureau")
      );
      const roleHealthCenter = JSON.parse(
        sessionStorage.getItem("roleHealthCenter")
      );
      const allArr = [...roleHealthBureauArr, ...roleHealthCenter];
      return allArr.includes(loginHealthCode);
    },
  },

  methods: {
    openFileInput(item) {
    const index = this.items.indexOf(item);
    const fileInput = this.$el.querySelector(`[data-index="${index}"]`);
    if (fileInput) {
      fileInput.click();
    }
    },
    openFileInputStudyPlan(item) {
    const index = this.items.indexOf(item);
    const fileInput = this.$el.querySelector(`[data-index="${index+1}"]`);
    if (fileInput) {
      fileInput.click();
    }
    },
    redirectToPreviewPage(id) {
    // 使用绝对路径
        window.open(`http://localhost:50430/UploadFile/Qualification/${id}/${id}.pdf`, '_blank');
        //window.open(`http://192.168.5.137/API/UploadFile/Qualification/${id}/${id}.pdf`, '_blank');
    },
    redirectstudyPlan(id){
        window.open(`http://localhost:50430/UploadFile/StudyPlan/${id}/${id}.pdf`, '_blank');
        //window.open(`http://192.168.5.137/API/UploadFile/Qualification/${id}/${id}.pdf`, '_blank');
    },
    async getExcel() {
      const top = 500000;
      const skip = 0;
      const obj = { top, skip };
      let qs = buildQuery(obj);
      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
        // qs = this.$equalFilter("SchoolCode", qs, school);
      }
      let bQs = false;
      if (this.searchName) {
        qs = this.$equalSubstringofFilter("Name", qs, this.searchName);
        bQs = true;
      }

      if (this.searchId) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
        } else {
          qs = this.$equalSubstringofFilter("Identifier", qs, this.searchId);
        }
        bQs = true;
      }
      if (this.searchGender) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Gender", qs, this.searchGender);
        } else {
          qs = this.$equalSubstringofFilter("Gender", qs, this.searchGender);
        }
        bQs = true;
      }
      if (
        Boolean(this.searchBirthday.year) &&
        Boolean(this.searchBirthday.month) &&
        Boolean(this.searchBirthday.day)
      ) {
        const date = `${this.searchBirthday.year}-${this.searchBirthday.month}-${this.searchBirthday.day}`;
        const startDate = moment(this.$usDate(date))
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const endDate = this.$usDate(date);
        if (bQs) {
          qs = this.$appendTimeFilter("BirthDate", qs, startDate, endDate);
        } else {
          qs = this.$equalTimeFilter("BirthDate", qs, startDate, endDate);
        }
        bQs = true;
      }
      if (this.searchSchool) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "SchoolCode",
            qs,
            this.searchSchool
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "SchoolCode",
            qs,
            this.searchSchool
          );
        }
        bQs = true;
      }

      if (this.searchEntryYear) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "StartYear",
            qs,
            this.searchEntryYear
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "StartYear",
            qs,
            this.searchEntryYear
          );
        }
        bQs = true;
      }

      if (this.searchEndYear) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("EndYear", qs, this.searchEndYear);
        } else {
          qs = this.$equalSubstringofFilter("EndYear", qs, this.searchEndYear);
        }
        bQs = true;
      }
      if (this.searchMajor) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("MajorName", qs, this.searchMajor);
        } else {
          qs = this.$equalSubstringofFilter("MajorName", qs, this.searchMajor);
        }
        bQs = true;
      }
      if (this.searchCategory) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "Country",
            qs,
            this.searchCategory
          );
        } else {
          qs = this.$equalSubstringofFilter("Country", qs, this.searchCategory);
        }
        bQs = true;
      }

      if (this.searchHometown) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("County", qs, this.searchHometown);
        } else {
          qs = this.$equalSubstringofFilter("County", qs, this.searchHometown);
        }
        bQs = true;
      }
      if (this.searchStatus) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "ServiceItem",
            qs,
            this.searchStatus
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "ServiceItem",
            qs,
            this.searchStatus
          );
        }
        bQs = true;
      }
      if (this.searchServiceNow) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "ServiceDetail",
            qs,
            this.searchServiceNow
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "ServiceDetail",
            qs,
            this.searchServiceNow
          );
        }
        bQs = true;
      }
      const url = `StudentInfo/GetExportResult2${qs}`;
      try {
        this.loadingExcel = true;
        const res = await window.axios.get(url);
        this.loadingExcel = false;
        const excelUrl = `${this.domainObject.platformOriginal}${res}`;
        window.open(excelUrl);
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async fileChange(e) {
    let form = new FormData();
    form.append("input",  e.target.files[0])
     e.target.value = '';
      try {
        const map = await window.axios.post(
          "StudentBasicInfo/ImportStudentBasicInfo",
          form
        );
        this.$bvToast.toast(`新增成功!!`, {
          title: "上傳檔案",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData()
      } catch (e) {
        this.$bvToast.toast("新增失敗!! " + e, {
          title: "上傳檔案",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async fileChangeQualification(id, index) {
      const fileInput = this.$el.querySelector(`[data-index="${index}"]`);

      if (fileInput) {
        const selectedFile = fileInput.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
          const map = await window.axios.post(
            `StudentBasicInfo/ImportStudentQualification?id=${id}`,
            formData
          );
          this.$bvToast.toast(`新增成功!!`, {
            title: "上傳檔案",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.getData();
        } catch (e) {
          this.$bvToast.toast("新增失敗2!! " + e, {
            title: "上傳檔案",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } else {
        console.error("找不到符合條件的元素");
      }
    },

    async fileChangeStudyPlan(id, index) {
      const fileInput = this.$el.querySelector(`[data-index="${index+1}"]`);

      if (fileInput) {
        const selectedFile = fileInput.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
          const map = await window.axios.post(
            `StudentBasicInfo/ImportStudentStudyPlan?id=${id}`,
            formData
          );
          this.$bvToast.toast(`新增成功!!`, {
            title: "上傳檔案",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.getData();
        } catch (e) {
          this.$bvToast.toast("新增失敗1!! " + e, {
            title: "上傳檔案",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } else {
        console.error("找不到符合條件的元素");
      }
    },

    downloadfile(evt) {
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href ="在地養成匯入範例.xlsx"
      console.log(link.href);
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    editLocalData(item) {
      store.editItem = { ...item };
      this.$bvModal.show("localStudentDataEdit");
    },
    openProgressRecord(item) {
      // store.editItem = { ...item };
      store.studentProgressInfo = { ...item };
      this.$bvModal.show("studentProgress");
    },
    openFeeClaim(item) {
      store.editItem = { ...item };
      this.$bvModal.show("feeClaimDetail");
    },
    openContract(item) {
      store.editItem = { ...item };
      store.nowIndex = 21;
      this.$router.push("contractview");
    },
    openRepayRecord(item) {
      if (item.status != "履約終止") {
        return;
      }
      store.editItem = { ...item };
      this.$bvModal.show("contractrepayrecord");
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchName = "";
      this.searchId = "";
      this.searchGender = null;
      this.searchBirthday = {
        year: "",
        month: "",
        day: "",
      };
      this.searchSchool = null;
      this.searchEntryYear = null;
      this.searchEndYear = null;
      this.searchMajor = null;
      this.searchCategory = null;
      this.searchHometown = null;
      this.searchStatus = null;
      this.searchServiceNow = null;
      this.getData();
    },
    async getData() {

      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      qs = `${qs}&$filter=BasicCode eq '1'`;

      if (this.searchName) {
        qs = this.$appendSubstringofFilter("Name", qs, this.searchName);
      }
      if (this.searchId) {
        qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
      }
      

      try {
        const { Items, Count } = await window.axios.get(
          `StudentBasicInfo/GetStudentBasicInfo${qs}`
        );
          // 修改資料處理部分
        this.items = Items.map((s) => ({

            name: s.Name || "",
            id: s.Identifier || "",
            gender: !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女",
            birthday: s.BirthDate || "",
            phoneNumber: s.PhoneNumber || "",
            address: s.Address || "",
            nationality: s.Nationality || "",
            preferenceOrder: s.PreferenceOrder || "",
            qualificationProof: s.QualificationProof || "",
            studyPlan: s.StudyPlan || "",
            BasicCode: s.BasicCode || "",
          }));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
    async getHomeTown() {
      const arr = await window.axios.get("SysCode/Get?category=country");
      this.hometownObject = arr.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));
      this.hometownObject.unshift({ value: null, text: "全部" });
    },
    async getMajor() {
      const arr = await window.axios.get("SysCode/Get?category=major");
      this.majorObject = arr.map((s) => ({
        value: s.Display,
        text: s.Display,
      }));
      this.majorObject.unshift({ value: null, text: "全部" });
    },
    async getCountry() {
      const arr = await window.axios.get("SysCode/Get?category=hometown");
      this.countryObject = arr.map((s) => ({
        value: s.Display,
        text: s.Display,
      }));
      this.countryObject.unshift({ value: null, text: "全部" });
    },
    async getAllHealth() {
      const arr1 = await window.axios.get(
        "SysRole/GetRoleInstitutions?no=user1"
      );
      const arr2 = await window.axios.get(
        "SysRole/GetRoleInstitutions?no=user4"
      );

      this.allHealthOption = [...arr1, ...arr2];
    },
  },
  async mounted() {
    await this.getAllHealth();
    await this.getAllSchool();
    await this.getHomeTown();
    await this.getCountry();
    await this.getMajor();
    await this.getData();
    this.$root.$on("get-Newest-LocalstuData", () => this.getData());
  },

  watch: {
    searchEntryYear(v) {
      if (+v < 0) {
        this.searchEntryYear = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchEntryYear = result));
    },
    searchEndYear(v) {
      if (+v < 0) {
        this.searchEndYear = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchEndYear = result));
    },
    "searchBirthday.year"(v) {
      if (+v < 0) {
        this.searchBirthday.year = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.year = result));
    },
    "searchBirthday.month"(v) {
      let value = v;
      if (+v < 0) {
        this.searchBirthday.month = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.month = result));
    },
    "searchBirthday.day"(v) {
      let value = v;
      if (+v < 0) {
        this.searchBirthday.day = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.day = result));
    },

    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    searchStatus(v) {
      if(v){
        this.serviceOption = this.allServiceObject[v];
        this.searchServiceNow = v=='畢業'?'畢業':null;
      }else{
        this.searchServiceNow = null;
        this.serviceOption = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">

.uploadfile {
  background-color: #4cb150;
  color: white;
  width: 70px;
  height: 30px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px; 
  /* 使用 Flexbox 將按鈕水平和垂直置中 */
  align-self: center; /* 垂直居中 */
  justify-self: center; /* 水平居中 */
}

.uploadfile0 {
  background-color: #ef791e;
  color: white;
  width: 70px;
  height: 30px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px; 
  /* 使用 Flexbox 將按鈕水平和垂直置中 */
  align-self: center; /* 垂直居中 */
  justify-self: center; /* 水平居中 */
}
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  width: 1600px;
  display: grid;
  grid-template-columns:
    repeat(1, 80px) repeat(12, 169px) repeat(2, 200px) 130px 150px
    160px
    380px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(3, 283px);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtc-search1 {
  grid-template-columns: 343px max-content 356px repeat(30, max-content);
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  width: 1600px;
  height: 100px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>
